.dropdown-body {
  display: none;
}

.open {
  display: block;
}

.icon {
  margin-left: 0.5em;
  transform: rotate(0deg);
  transition: all 0.2s ease-in-out;
}

.icon.open {
  transform: rotate(180deg);
}

h5 {
  margin: 0px;
}

ul {
  padding-left: 1em;
}
