.landingPage-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: var(--max-width);
  margin: 3rem auto;
  gap: 2.5rem;
}

.landingPage-grid > img {
  max-width: 100%;
  object-fit: cover;
}

.splash-heading {
  font-size: 2rem;
  font-family: Custom-bold;
  margin-top: 2rem;
}

.pseudo-btn {
  background-color: var(--accent-color);
  color: var(--main-bg-color);
  font-family: Custom-medium;
  font-size: 1em;

  border: 2px solid var(--accent-color);
  padding: 0.4em 1.6em;
  border-radius: 2em;
}

.pseudo-btn-hollow {
  background-color: var(--main-bg-color);
  outline: var(--accent-color);
  color: var(--accent-color);
  font-family: Custom-medium;
  font-size: 1em;

  border: 2px solid var(--accent-color);
  padding: 0.4em 1.6em;
  border-radius: 2em;
}

.hollow {
  background-color: var(--main-bg-color);
  outline: var(--accent-color);
  color: var(--accent-color);
}

.hollow:hover {
  color: var(--main-bg-color);
  background-color: var(--accent-color);
}

.splash-btn-belt {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.start-btn-wrapper {
  display: flex;
  flex-direction: row-reverse;
}

.start-btn-wrapper > button {
  font-size: 1.5rem;
  cursor: pointer;
}

.start-btn-wrapper > button :hover {
  background-color: var(--accent-color-washed);
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .landingPage-grid {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    padding: 0 2em;
  }
}
