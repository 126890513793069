header {
  background: white;
  position: fixed;
  background-color: white;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3;
  margin-bottom: 150px;
}

body {
  margin-top: 250px;
}

a:focus {
  outline: 5px dotted #0090da;
}

.topnav {
  overflow: hidden;
  display: grid;
  grid-template-columns: 200px 1fr;
  border-bottom: 1px solid #eee;
  box-shadow: 0 20px 27px -26px rgba(0, 0, 0, 0.15);

  max-width: var(--max-width);
  margin-inline: auto;
  /* position: fixed;
  background-color: white;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3; */
}

.logoWrap {
  grid-row: span 2;
  align-self: center;
  justify-self: center;
  border-right: 1px solid #eee;
  width: 100%;
  height: 100%;
  text-align: center;
}

img.logo {
  cursor: pointer;
  padding: 1.5em;
  transition: 0.2s ease all;
  max-width: 160px;
  float: left;
}

img.logo:hover {
  box-shadow: 0px 4px rgba(255 255 255 / 30%);
  pointer: cursor;
  transition: 0.2s ease all;
}

.topnav a {
  float: left;
  display: block;
  color: #222;
  text-align: center;
  padding: 20px 16px;
  text-decoration: none;
  font-size: 15px;
  transition: 0.2s ease all;
}

.topnav a:hover {
  background-color: #fafafa;
  text-decoration: underline;
  transition: 0.2s ease all;
}

.topnav a.active {
  background-color: #04aa6d;
  color: white;
}

.nav-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-right: 1px solid #eee;
}

.menu-title {
  display: none;
}

.topnav .icon {
  display: none;
}

.firstRow {
  justify-self: end;
}

.firstRow a {
  color: #555;
}

.firstRow a:not(:last-child)::after {
  content: "|";
  padding: 0em;
  margin-left: 2em;
  color: #ccc;
}
.firstRow a:hover::after {
  display: inline-block;
}
.firstRow a:last-child {
  margin-right: 5em;
}

.nav-primary {
  justify-self: start;
  width: 100%;
  border-top: 1px solid #eee;
}

.nav-primary a {
  font-size: 16px;
}

.nav-primary a:first-child {
  padding-left: 2em;
}

@media (max-width: 1000px) {
  body {
    margin-top: 180px;
  }

  img.logo {
    padding: 0.8em;
  }

  /* .container {
    padding-top: 7em;
  } */

  .topnav {
    grid-template-columns: 1fr;
    border-bottom: 1px solid var(--primaryColor);
    overflow: initial;
  }

  .topnav a {
    transition: 0.3s ease all;
  }

  .nav-wrap {
    font-family: "vic-semibold";
    position: absolute;
    left: 0;
    right: 0;
    background-color: white;
    height: 100vh;
    -webkit-transform: translateX(101%);
    transform: translateX(101%);
    transition: 0.2s all;
  }

  .menu-title {
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;
    padding: 1em 0;
    background-color: #f1f1f1;
  }

  .menu-title p {
    padding: 2em;
    font-family: custom-regular;
  }

  .topnav .nav-primary a,
  .topnav .firstRow a {
    width: 100%;
    text-align: left;
  }

  .topnav .nav-primary a:hover,
  .topnav .firstRow a:hover {
    text-decoration: none;
  }

  .nav-primary {
    width: 100%;
    border: 0;
  }

  .topnav a {
    color: var(--primaryColor);
    float: left;
  }

  .firstRow {
    /*justify-self: initial;*/
    width: 100%;
  }

  .logoWrap {
    grid-row-start: 1;
  }

  .topnav .icon {
    padding: 2em;
    justify-self: right;
    display: block;
    grid-row-start: 1;
    position: absolute;
    right: 0;
  }

  .topnav a.icon:hover {
    background: none;
  }

  .topnav a.icon:focus {
    outline: none;
    border: 0;
    outline: 5px dotted var(--accent-color);
  }

  .topnav a.icon i.fa.fa-bars {
    font-size: 24px;
  }

  .topnav.responsive a.icon i::before {
    content: "\f00d";
    font-family: normal 900 1em/1 "Font Awesome 6 Free";
  }

  /* .topnav.responsive {
    position: fixed;
  } */

  .topnav.responsive .nav-wrap {
    /* max-height: 600px;*/
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
    border-top: 1px solid #eee;
  }

  .topnav.responsive a:not(.icon):hover {
    background-color: #d1dbf2;
  }

  .firstRow a:last-child {
    margin-right: initial;
  }

  .firstRow a:not(:last-child)::after {
    content: "";
  }

  .topnav .nav-primary a:first-child,
  .topnav a {
    font-weight: 600;
    padding-left: 2em;
    padding-bottom: 1em;
    font-size: 16px;
  }

  .firstRow a {
    font-family: Custom-bold;
  }

  .nav-primary a {
    font-family: Custom-bold;
  }
}

@media (min-width: 700px) and (max-width: 1000px) {
  .topnav.responsive .nav-wrap {
    box-shadow: -2px 0px 20px rgba(0, 0, 0, 0.5);
    -webkit-transform: translateX(35%);
    transform: translateX(35%);
  }
}

.open {
  max-height: fit-content;
}
.close {
  max-height: 0;
}

.at-banner {
  background-color: var(--accent-color);
}
.at-banner .banner-text {
  color: white;
  font-size: 18px;
  padding: 0.8rem;
  text-align: center;
}
