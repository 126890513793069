hr {
  border: 1px solid #e4e4e4;
}

a {
  color: var(--accent-color);
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

.ul-dotless {
  list-style: none;
  padding-left: 0;
}

.contact-links .ul-dotless > li {
  text-decoration: underline;
}

.ul-dotless > li {
  margin: 1rem 0px;
}

@media only screen and (max-width: 800px) {
  footer {
    padding: 0 2em;
  }
}

.socials-section {
  max-width: var(--max-width);
  margin: 0 auto;
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-content: space-between;
  padding-block: 3rem;
}

.socials-section .left-section {
  justify-self: start;
}

.socials-section .right-section {
  justify-self: end;
}

.social-button-belt {
  display: flex;
  gap: 2rem;
}

.social-button-belt button {
  border-radius: 0;
}

.socials-ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.socials-ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.socials-ul i {
  margin-right: 10px; /* Adjust the margin as needed */
  font-size: 24px; /* Set a common font size for all icons */
  width: 20px;
}

.footer-section-title {
  font-family: Custom-bold;
  font-size: 1.5em;
}

@media only screen and (max-width: 800px) {
  .socials-section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .social-button-belt {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }
}

.contact-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: var(--max-width);
  margin: 0 auto;
  justify-content: space-between;
  padding-block: 3rem;
}

.contact-links {
  display: flex;
  flex-direction: row;
  gap: 3rem;
}

.ack-excerpt {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.ack-excerpt > div {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.flag-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.flag {
  width: 63px;
}

@media only screen and (max-width: 800px) {
  .contact-section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .ack-excerpt > div {
    flex-direction: column;
  }

  .flag-wrapper {
    flex-direction: row;
  }
}

.copyright-section {
  max-width: var(--max-width);
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-block: 3rem;
}

.copyright-section > img {
  width: 120px;
  object-fit: contain;
}

.copyright-text > li {
  color: #66686c;
}

.back-button {
  font-size: 1.5em;
  margin-top: 1em;
  cursor: pointer;
  color: var(--main-bg-color);
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  vertical-align: middle;
  padding: 6px;
  background: var(--accent-color);
  width: 40px;
  height: 40px;
}

@media only screen and (max-width: 800px) {
  .copyright-section {
    flex-direction: column;
    gap: 1rem;
  }
}

button:focus {
  outline: 5px dotted #0090da;
}

.tabable:focus {
  outline: 5px dotted #0090da;
}

.fa-facebook-f {
  color: #3c5997;
}

.fa-linkedin-in {
  color: #027bb5;
}

.fa-youtube {
  color: #ba0101;
}

.fa-weixin {
  color: #538e1e;
}

.fa-instagram {
  color: #3c5997;
}
